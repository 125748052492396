export default {
  'global#home': 'Home',
  'global#maps': 'Mappe',
  'global#works': 'Opere',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Spiacenti, si è verificato un errore',
  'global#layout_error_description': 'Prova a ricaricare la pagina tra qualche istante e se il problema persiste contatta l\'amministratore della piattaforma.',
  'readmore#label': 'Mostra tutto',
  'search#works_total': '<strong>{ total }</strong> Opere',
  'search#works_total_1': '<strong>{ total }</strong> Opera',
  'search#works_total_0': 'Nessuna Opera',
  'search#books_total': '<strong>{ total }</strong> Libri',
  'search#books_total_1': '<strong>{ total }</strong> Libro',
  'search#books_total_0': 'Nessun Libro',
  'search#witnesses_total': '<strong>{ total }</strong> Testimoni',
  'search#witnesses_total_1': '<strong>{ total }</strong> Testimone',
  'search#witnesses_total_0': 'Nessun Testimone',
  'search#placeholder_query': 'Cerca nei titoli',
  'search#placeholder_date': 'Cerca negli anni',
  'search#placeholder_query-content': 'Cerca nelle opere contenute',
  'search#header_authors': 'Autori',
  'search#header_keywords': 'Keywords',
  'search#header_works': 'Opere',
  'search#header_libraries': 'Biblioteca',
  'search#header_cities': 'Città',
  'search#header_dates': 'Date',
  'search#header_sources': 'Fonti',
  'search#header_projects': 'Piattaforma di provenienza',
  'search#facets_title': 'Filtra i risultati',
  'search#filters_title': 'Filtri attivi:',
  'search#sort_title': 'Ordine',
  'search#sort_score': 'Ordine per pertinenza',
  'search#sort_asc': 'Ordine alfabetico (A→Z)',
  'search#sort_desc': 'Ordine alfabetico (Z→A)',
  'search#fallback_text': 'La tua ricerca non ha dato risultati. Prova a cambiare i parametri oppure a resettare la ricerca cliccando sul pulsante sottostante.',
  'search#fallback_button': 'Resetta la ricerca',
  'search#ko_text': 'Oops, abbiamo riscontrato un errore nella ricerca. Prova a cambiare i parametri oppure a resettare la ricerca cliccando sul pulsante sottostante.',
  'search#ko_button': 'Resetta la ricerca',
  'search#description_button': 'Info',
  'search#description_link': 'Ok, ho capito',
  'resource#authors': 'di',
  'resource#date': 'pubblicato il',
  'resource#time_to_read': 'lettura di',
  'resource#metadata': 'Metadati',
  'resource#metadata_size': 'Dimensioni',
  'resource#collection-rewritings': 'Riscritture',
  'search#header_genres': 'Genere',
  'search#header_language': 'Lingua',
  'search#date': 'data',
  'search#bibliographies_total': '<strong>{ total }</strong> riferimenti bibliografici',
  'search#bibliographies_total_1': '<strong>{ total }</strong> riferimento bibliografico',
  'search#bibliographies_total_0': 'Nessun riferimento bibliografico',
  'search#placeholder_authors': 'Cerca un autore',
  'search#title_bibliographies': 'Bibliografia',
  'search#biographies_total': '<strong>{ total }</strong> vite antiche',
  'search#biographies_total_1': '<strong>{ total }</strong> vita antica',
  'search#biographies_total_0': 'Nessuna vita antica',
  'search#title_biographies': 'Vite antiche',
  'search#iconographies_total': '<strong>{ total }</strong> iconografie',
  'search#iconographies_total_1': '<strong>{ total }</strong> icongrafia',
  'search#iconographies_total_0': 'Nessuna iconografia',
  'search#title_iconographies': 'Vite antiche',
  'search#tools_total': '<strong>{ total }</strong> materiali e strumenti',
  'search#tools_total_1': '<strong>{ total }</strong> materiale e strumento',
  'search#tools_total_0': 'Nessun materiale e strumento',
  'search#title_tools': 'Materiali e strumenti',
  'advancedsearch#label_language': 'Lingua',
  'advancedsearch#label_subject': 'Soggetto',
  'advancedsearch#label_source': 'Fonte',
  'advancedsearch#label_editor': 'Editore',
  'advancedsearch#works_total': '<strong>{ total }</strong> Risultati',
  'advancedsearch#works_total_1': '<strong>{ total }</strong> Risultato',
  'advancedsearch#works_total_0': 'Nessun Risultato',
  'advancedsearch#title': 'Risultati',
};
