import searchWorksFacetsConfig from './search-works-facets.config';

export default {
  title: 'search#title_works',
  searchId: 'work',
  searchConfig: searchWorksFacetsConfig,
  resourcePath: '/work',
  facetsTitle: 'search#facets_title',
  totalResultsText: 'search#works_total',
  filtersTitle: 'search#filters_title',
  grid: 1,
  sort: {
    label: 'search#sort_title',
    options: [
      // {
      //   value: 'sort_date_ASC',
      //   label: 'search#sort_date_asc',
      //   selected: false
      // },
      // {
      //   value: 'sort_date_DESC',
      //   label: 'search#sort_date_desc',
      //   selected: false
      // },
      {
        value: 'sort_title_ASC',
        label: 'search#sort_asc',
        selected: true
      },
      {
        value: 'sort_title_DESC',
        label: 'search#sort_desc',
        selected: false
      }
    ]
  },
  pagination: {
    limit: 5,
    options: [
      12,
      24,
      48
    ]
  },
  itemPreview: {
    classes: ''
  },
  fallback: {
    text: 'search#fallback_text',
    button: 'search#fallback_button'
  },
  ko: {
    text: 'search#ko_text',
    button: 'search#ko_button'
  }
};
