export default {
  searchId: 'advanced_search',
  title: 'advancedsearch#results_title',
  resourcePath: '/obra',
  totalResultsText: 'advancedsearch#works_total',
  grid: 1,
  filters: {
    // title: 'advancedsearch#filters_title',
    labels: {
      'resource-type': 'advancedsearch#label_resource_type',
      'query-fulltext': 'advancedsearch#fulltext_text',
      'query-title': 'advancedsearch#label_title',
      'query-author': 'advancedsearch#label_author',
      'query-edition': 'advancedsearch#label_edition',
      mrc_bibl_record_post_title: 'advancedsearch#label_source',
      'query-date': 'advancedsearch#label_date',
      'query-synopsis': 'advancedsearch#label_synopsis',
      'query-note': 'advancedsearch#label_note',
      'query-language': 'advancedsearch#label_language',
      'query-subject': 'advancedsearch#label_subject',
      'query-editor': 'advancedsearch#label_editor',
      // 'query-distance-text': 'advancedsearch#label_distance',
      // 'query-bibl': 'advancedsearch#label_bibl',
      // author: 'advancedsearch#label_author',
      query: 'advancedsearch#label_title',
      // censors: 'advancedsearch#label_censors',
      // signature: 'advancedsearch#label_signature',
      // year: 'advancedsearch#label_date',
    }
  },
  sort: {
    label: 'search#sort_title',
    options: [
      {
        value: '_score',
        label: 'search#sort_score',
        selected: true,
        disabled: false
      },
      {
        value: 'sort_ASC',
        label: 'search#sort_asc',
        selected: false
      },
      {
        value: 'sort_DESC',
        label: 'search#sort_desc',
        selected: false
      }
    ]
  },
  pagination: {
    selectLabel: 'search#results_number',
    limit: 5,
    options: [
      12,
      24,
      48
    ]
  },
  itemPreview: {
    // classes: 'is-vertical'
  },
  fallback: {
    text: 'search#fallback_text',
    button: 'search#fallback_button'
  },
  ko: {
    text: 'search#ko_text',
    button: 'search#ko_button'
  }
};
