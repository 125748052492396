export default {
  logo: {
    title: 'Mapping Chivalry',
    subtitle: 'Spanish Romances of Chivalry from Renaissance to 21st Century. A digital approach',
    anchor: {
      href: '/'
    }
  },
  nav: {
    items: []
  }
};
