import { ConfigMurucaAdvancedSearchLayout } from '@net7/boilerplate-muruca';

const config: ConfigMurucaAdvancedSearchLayout = {
  title: 'advancedsearch#title',
  resultsUrl:  { es: 'resultados', it: 'it/resultados', en: 'en/resultados' },
  hasDynamicOptions: true,
  formConfig: {
    submitButton: {
      label: 'advancedsearch#submit',
    },
    resetButton: {
      label: 'advancedsearch#reset',
    },
    groups: [
      {
        id: 'group-1',
        sections: ['section-1'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_global',
          isOpen: true,
          showHeader: false,
        },
      },
    ],
    sections: [
      {
        id: 'section-1',
        title: '',
        inputs: [
          {
            id: 'query-fulltext',
            type: 'text',
            data: {
              id: 'query-fulltext',
              label: 'advancedsearch#fulltext_text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-title',
            type: 'text',
            data: {
              id: 'query-title',
              label: 'advancedsearch#label_title',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'work_author',
            type: 'select',
            data: {
              id: 'work_author',
              label: 'advancedsearch#label_author',
              options: []

            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-date',
            type: 'text',
            data: {
              id: 'query-date',
              label: 'advancedsearch#label_date',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-edition',
            type: 'text',
            data: {
              id: 'query-edition',
              label: 'advancedsearch#label_edition',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-bibl',
            type: 'text',
            data: {
              id: 'query-bibl',
              label: 'advancedsearch#label_bibliography',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-language',
            type: 'select',
            data: {
              id: 'query-language',
              label: 'advancedsearch#label_language',
              options: [
                {
                  value: '',
                  label: '---'
                },
                {
                  value: 'castellano',
                  label: 'Castellano'
                },
                {
                  value: 'catalán',
                  label: 'Catalán'
                },
                {
                  value: 'gallego',
                  label: 'Gallego'
                },
                {
                  value: 'italiano',
                  label: 'Italiano'
                },
                {
                  value: 'spagnolo',
                  label: 'Spagnolo'
                }
              ]
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-subject',
            type: 'select',
            data: {
              id: 'query-subject',
              label: 'advancedsearch#label_subject',
              options: [
                {
                  value: '',
                  label: '---'
                },
                {
                  label: 'poema cavalleresco',
                  value: 'poema_cavalleresco'
                },
                {
                  label: 'romanzo cavalleresco',
                  value: 'romanzo_cavalleresco'
                },
                {
                  label: 'riscrittura cavalleresca moderna',
                  value: 'riscrittura_cavalleresca moderna'
                },
                {
                  label: "libretto d'opera",
                  value: "libretto_d'opera"
                },
                {
                  label: 'poema cavalleresco',
                  value: 'poema_cavalleresco'
                },
                {
                  label: 'romanzo',
                  value: 'romanzo'
                },
                {
                  label: 'altro',
                  value: 'altro'
                }
              ]

            },
            state: {
              value: [],
              disabled: false,
              hidden: false,
            },
          },

          {
            id: 'mrc_bibl_record_post_title',
            type: 'select',
            data: {
              id: 'mrc_bibl_record_post_title',
              label: 'advancedsearch#label_source',
              options: []
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-editor',
            type: 'text',
            data: {
              id: 'query-editor',
              label: 'advancedsearch#label_editor',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
    ],
  },
};

export default config;
