export default {
  // Global
  'global#home': 'Home',
  'global#works': 'Works',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Sorry, an error occurred',
  'global#layout_error_description': 'Please try reloading the page in a moment. If the issue persists, contact the platform administrator.',
  'global#facet_empty_text': 'No results',

  // Advanced Search
  'advancedsearch#title': 'Advanced Search',
  'advancedsearch#fulltext_text': 'Search the portal (all fields)',
  'advancedsearch#section_global': 'Search the portal',
  'advancedsearch#label_title': 'Title',
  'advancedsearch#label_manuscript_content': '',
  'advancedsearch#label_author': 'Author',
  'advancedsearch#label_edition': 'Editions',
  'advancedsearch#label_date': 'Date',
  'advancedsearch#label_synopsis': 'Synopsis',
  'advancedsearch#label_note': 'Notes',
  'advancedsearch#label_source': 'Chivalric sources',
  'advancedsearch#label_bibliography': 'Bibliography',
  'advancedsearch#reset': 'Clear fields',
  'advancedsearch#submit': 'Search',
  'advancedsearch#filters_title': 'Active filters',
  'advancedsearch#results_title': 'Results',
  'advancedsearch#works_total': '<strong>{ total }</strong> Results',
  'advancedsearch#works_total_1': '<strong>{ total }</strong> Result',
  'advancedsearch#works_total_0': 'No Results',
  'advancedsearch#label_language': 'Lenguage',
  'advancedsearch#label_subject': 'Subject',
  'advancedsearch#label_editor': 'Editor',

  // Search
  'search#title_works': 'Works',
  'search#title_bibliographies': 'Bibliography',
  'search#title_biographies': 'Ancient Lives',
  'search#title_iconographies': 'Ancient Lives',
  'search#title_tools': 'Materials and Tools',
  'search#editions_title': 'Digital Editions',
  'search#works_title': 'Mapping Chivalry <br>Progetto Mambrino Database Bibliografico (PMDB)',

  // Search Totals
  'search#results_number': 'Number of results',
  'search#works_total': '<strong>{ total }</strong> Works',
  'search#works_total_1': '<strong>{ total }</strong> Work',
  'search#works_total_0': 'No Works',
  'search#characters_total': '<strong>{ total }</strong> Characters',
  'search#characters_total_1': '<strong>{ total }</strong> Character',
  'search#motifs_total': '<strong>{ total }</strong> Motifs',
  'search#motifs_total_1': '<strong>{ total }</strong> Motif',
  'search#motifs_total_0': 'No Motifs',
  'search#places_total': '<strong>{ total }</strong> Places',
  'search#places_total_1': '<strong>{ total }</strong> Place',
  'search#places_total_0': 'No Places',
  'search#news_total': '<strong>{ total }</strong> News',
  'search#news_total_0': 'No News',

  // Search Headers
  'search#header_authors': 'Authors',
  'search#header_keywords': 'Keywords',
  'search#header_works': 'Works',
  'search#header_libraries': 'Library',
  'search#header_cities': 'Cities',
  'search#header_dates': 'Dates',
  'search#header_sources': 'Sources',
  'search#header_projects': 'Source Platform',
  'search#header_genres': 'Genre',
  'search#header_language': 'Language',
  'search#header_languages': 'Languages',
  'search#header_romances': 'Cycles',
  'search#header_subjects': 'Subjects',
  'search#header_translators': 'Translator',
  'search#header_types': 'Types',
  'search#header_collocations': 'Locations',
  'search#header_motif_type': 'Motif Types',
  'search#header_cycles': 'Cycles',
  'search#header_curators': 'Curators',
  'search#header_forenames': 'First Names',
  'search#header_in_works': 'Works of Appearance',
  'search#header_sex': 'Sex',
  'search#header_traits': 'Traits',

  // Search Placeholders
  'search#placeholder_query': 'Search in titles',
  'search#placeholder_date': 'Search in years',
  'search#placeholder_query-content': 'Search in contained works',
  'search#placeholder_authors': 'Search for an author',
  'search#placeholder_types': 'Search in types',
  'search#character_placeholder_query': 'Search in names',
  'search#motif_placeholder_query': 'Search for motif',

  // Search UI
  'search#facets_title': 'Filter Results',
  'search#filters_title': 'Active Filters:',
  'search#sort_title': 'Sort',
  'search#sort_score': 'Order by relevance',
  'search#sort_asc': 'Alphabetical order (A→Z)',
  'search#sort_desc': 'Alphabetical order (Z→A)',
  'search#sort_date_asc': 'Order by date (ASC)',
  'search#sort_date_desc': 'Order by date (DESC)',
  'search#sort_label_ciclo': 'Order by position in the cycle',
  'search#resultsamount': 'Results per page',
  'search#date': 'date',

  // Search Messages
  'search#fallback_text': 'Your search returned no results. Try changing the parameters or reset the search by clicking the button below.',
  'search#fallback_button': 'Reset search',
  'search#ko_text': 'Oops, we encountered an error in the search. Try changing the parameters or reset the search by clicking the button below.',
  'search#ko_button': 'Reset search',
  'search#description_button': 'Info',
  'search#description_link': 'Ok, I understand',

  // Resource
  'resource#authors': 'by',
  'resource#date': 'published on',
  'resource#time_to_read': 'reading time',
  'resource#metadata': 'Metadata',
  'resource#metadata_size': 'Size',
  'resource#collection-rewritings': 'Rewritings',
  'resource#collection-translations': 'Related translations',
  'resource#collection-sources': 'Related sources',
  'resource#related_records': 'Related sources',
  'resource#page_bibliographic_data': 'Work > Bibliographic data',
  'resource#page_codicological_data': 'Work > Codicological data',
  'resource#page_composition_process': 'Work > Composition process',
  'resource#page_cited_bibliography': 'Work > Cited bibliography',
  'resource#complete-title': 'Complete title',
  'resource#collection-editions': 'Related editions',
  'resource#collection-samples': 'Samples',
  'resource#collection-digital-editions': 'Digital editions',
  'resource#collection-text': 'Digital edition',
  'resource#parent-record': 'Reference work',
  'resource#parent-edition': 'Edition',
  'resource#occurrences': 'Occurrences',

  // Tabs
  'tabs#work_bibliographic_data': 'Bibliographic data',
  'tabs#work_codicological_data': 'Codicological data',
  'tabs#work_composition_process': 'Composition process',
  'tabs#work_cited_bibliography': 'Cited bibliography',

  // Read More
  'readmore#label': 'Show all',
  'readmore#more': 'Show more',
  'readmore#less': 'Show less',

  // Author
  'author.name': 'Author',

  // Editions
  'old_editions.edition': 'Ancient editions',
  'modern_editions.edition': 'Modern editions'
};
