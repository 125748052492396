export default {
  title: 'Home',
  // bodyClasses: 'has-transparent-header is-home-miseria',
  bodyClasses: '',
  sections: [
    // {
    //   id: 'hero-main',
    //   type: 'hero',
    //   grid: null,
    //   options: {
    //     classes: 'n7-hero-main',
    //     background: true
    //   }
    // },
    {
      id: 'hero-works',
      type: 'hero',
      grid: null,
      options: {
        classes: 'n7-hero-works',
        background: true
      }
    },
    {
      id: 'collection-hero',
      type: 'collection',
      grid: 4,
      options: {
        classes: 'is-vertical overlay_bottom',
        itemPreview: {
          limit: null,
          striptags: false
        }
      }
    },
  ]
};
