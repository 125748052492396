import {
  // COMMON
  Page404LayoutComponent,
} from '@net7/boilerplate-common';
import {
  // MURUCA
  MrHomeLayoutComponent,
  MrSearchLayoutComponent,
  MrStaticLayoutComponent,
  MrResourceLayoutComponent,
  MrAdvancedSearchLayoutComponent,
  MrAdvancedResultsLayoutComponent,
  // OTHER
  LocaleDependenciesGuard
} from '@net7/boilerplate-muruca';

type RouteConfig = {
  component?: any;
  paths: {
    [locale: string]: string;
  };
  data?: any;
  isRedirect?: boolean;
  redirectTo?: {
    [locale: string]: string;
  };
}

const config: { [layoutID: string]: RouteConfig; } = {
  works: {
    paths: { it: 'it/opere', en: 'en/works', es: 'obras' },
    component: MrSearchLayoutComponent,
    data: { configId: 'search-works' }
  },
  work: {
    paths: { it: 'it/obra/:id/:slug', en: 'en/obra/:id/:slug', es: 'obra/:id/:slug' },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work' }
  },
  advancedSearch: {
    paths: { es: 'busqueda-avanzada', it: 'it/ricerca-avanzata', en: 'en/advanced-search' },
    component: MrAdvancedSearchLayoutComponent,
    data: { configId: 'advanced-search' }
  },
  advancedResults: {
    paths: { es: 'resultados', it: 'it/resultados', en: 'en/resultados' },
    component: MrAdvancedResultsLayoutComponent,
    data: { configId: 'advanced-results' }
  },

  post: { paths: { it: 'it/post/:slug', en: 'en/post/:slug', es: 'post/:slug' }, component: MrStaticLayoutComponent, data: { configId: 'post' } },
  bibl_record: { paths: { it: 'it/record/:id/:slug', en: 'en/record/:id/:slug', es: 'record/:id/:slug' }, component: MrResourceLayoutComponent, data: { configId: 'resource-record' } },
  records: { paths: { it: 'it/records', en: 'en/records', es: 'records' }, component: MrSearchLayoutComponent, data: { configId: 'search-records' } },

  home: {
    paths: { it: 'it/', en: 'en/', es: '' },
    component: MrHomeLayoutComponent,
    data: { configId: 'home' }
  },
  homeRedirect: {
    paths: { en: 'en', it: 'it' },
    isRedirect: true
  },
  page: {
    component: MrStaticLayoutComponent,
    paths: { it: ':slug', en: 'en/:slug' },
    data: { configId: 'page' }
  },
  page404: {
    paths: { it: 'page-404', en: 'en/page-404' },
    component: Page404LayoutComponent,
    data: { id: 'page-404' }
  }

};

const APP_ROUTES: any = [
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  }
];

/**
 * Generate angular routes from config
 */
Object.keys(config).forEach((routeId) => {
  const {
    component, data, paths, isRedirect, redirectTo
  } = config[routeId];
  Object.entries(paths).forEach(([locale, path]) => {
    // path to component
    if (component) {
      APP_ROUTES.push({
        path,
        component,
        data: { ...data, routeId, locale },
        canActivate: [LocaleDependenciesGuard]
      });
    }
    // catch route
    if (isRedirect) {
      APP_ROUTES.push(
        {
          path,
          redirectTo: redirectTo ? redirectTo[locale] : `${path}/`,
          data: { ...data, routeId, locale },
        }
      );
    }
  });
});

export { APP_ROUTES };
