export default {
  // Global
  'global#home': 'Home',
  'global#maps': 'Mappe',
  'global#works': 'Opere',
  'global#date_human': 'Do MMMM YYYY',
  'global#layout_error_title': 'Spiacenti, si è verificato un errore',
  'global#layout_error_description': 'Prova a ricaricare la pagina tra qualche istante e se il problema persiste contatta l\'amministratore della piattaforma.',
  'global#facet_empty_text': 'Nessun risultato disponibile',

  // Advanced Search
  'advancedsearch#title': 'Ricerca avanzata',
  'advancedsearch#fulltext_text': 'Cerca nel portale (in tutti i campi)',
  'advancedsearch#section_global': 'Cerca nel portale',
  'advancedsearch#label_title': 'Titolo',
  'advancedsearch#label_manuscript_content': '',
  'advancedsearch#label_author': 'Autore',
  'advancedsearch#label_edition': 'Edizioni',
  'advancedsearch#label_date': 'Data',
  'advancedsearch#label_synopsis': 'Sinossi',
  'advancedsearch#label_note': 'Note',
  'advancedsearch#label_source': 'Fonti cavalleresche',
  'advancedsearch#label_bibliography': 'Bibliografia',
  'advancedsearch#reset': 'Pulisci i campi',
  'advancedsearch#submit': 'Cerca',
  'advancedsearch#filters_title': 'Filtri attivi',
  'advancedsearch#results_title': 'Risultati',
  'advancedsearch#works_total': '<strong>{ total }</strong> Risultati',
  'advancedsearch#works_total_1': '<strong>{ total }</strong> Risultato',
  'advancedsearch#works_total_0': 'Nessun risultato',
  // Search
  'search#title_works': 'Opere',
  'search#title_bibliographies': 'Bibliografia',
  'search#title_biographies': 'Vite antiche',
  'search#title_iconographies': 'Vite antiche',
  'search#title_tools': 'Materiali e strumenti',

  // Search Totals
  'search#results_number': 'Numero di risultati',
  'search#works_total': '<strong>{ total }</strong> Opere',
  'search#works_total_1': '<strong>{ total }</strong> Opera',
  'search#works_total_0': 'Nessuna Opera',
  'search#books_total': '<strong>{ total }</strong> Libri',
  'search#books_total_1': '<strong>{ total }</strong> Libro',
  'search#books_total_0': 'Nessun Libro',
  'search#witnesses_total': '<strong>{ total }</strong> Testimoni',
  'search#witnesses_total_1': '<strong>{ total }</strong> Testimone',
  'search#witnesses_total_0': 'Nessun Testimone',
  'search#bibliographies_total': '<strong>{ total }</strong> riferimenti bibliografici',
  'search#bibliographies_total_1': '<strong>{ total }</strong> riferimento bibliografico',
  'search#bibliographies_total_0': 'Nessun riferimento bibliografico',
  'search#biographies_total': '<strong>{ total }</strong> vite antiche',
  'search#biographies_total_1': '<strong>{ total }</strong> vita antica',
  'search#biographies_total_0': 'Nessuna vita antica',
  'search#iconographies_total': '<strong>{ total }</strong> iconografie',
  'search#iconographies_total_1': '<strong>{ total }</strong> icongrafia',
  'search#iconographies_total_0': 'Nessuna iconografia',
  'search#tools_total': '<strong>{ total }</strong> materiali e strumenti',
  'search#tools_total_1': '<strong>{ total }</strong> materiale e strumento',
  'search#tools_total_0': 'Nessun materiale e strumento',

  // Search Headers
  'search#header_authors': 'Autori',
  'search#header_keywords': 'Keywords',
  'search#header_works': 'Opere',
  'search#header_libraries': 'Biblioteca',
  'search#header_cities': 'Città',
  'search#header_dates': 'Date',
  'search#header_sources': 'Fonti',
  'search#header_projects': 'Piattaforma di provenienza',
  'search#header_genres': 'Genere',
  'search#header_language': 'Lingua',
  'search#header_languages': 'Lingue',
  'search#header_romances': 'Cicli',
  'search#header_subjects': 'Temi',
  'search#header_translators': 'Traduttore',
  'search#header_types': 'Tipologie',
  'search#header_collocations': 'Collocazioni',

  // Search Placeholders
  'search#placeholder_query': 'Cerca nei titoli',
  'search#placeholder_date': 'Cerca negli anni',
  'search#placeholder_query-content': 'Cerca nelle opere contenute',
  'search#placeholder_authors': 'Cerca un autore',
  'search#placeholder_types': 'Cerca nelle tipologie',

  // Search UI
  'search#facets_title': 'Filtra i risultati',
  'search#filters_title': 'Filtri attivi:',
  'search#sort_title': 'Ordine',
  'search#sort_score': 'Ordine per pertinenza',
  'search#sort_asc': 'Ordine alfabetico (A→Z)',
  'search#sort_desc': 'Ordine alfabetico (Z→A)',
  'search#sort_date_asc': 'Ordine per data (ASC)',
  'search#sort_date_desc': 'Ordine per data (DESC)',
  'search#sort_label_ciclo': 'Ordine per posizione nel ciclo',
  'search#resultsamount': 'Risultati per pagina',
  'search#date': 'data',

  // Search Messages
  'search#fallback_text': 'La tua ricerca non ha dato risultati. Prova a cambiare i parametri oppure a resettare la ricerca cliccando sul pulsante sottostante.',
  'search#fallback_button': 'Resetta la ricerca',
  'search#ko_text': 'Oops, abbiamo riscontrato un errore nella ricerca. Prova a cambiare i parametri oppure a resettare la ricerca cliccando sul pulsante sottostante.',
  'search#ko_button': 'Resetta la ricerca',
  'search#description_button': 'Info',
  'search#description_link': 'Ok, ho capito',

  // Resource
  'resource#authors': 'di',
  'resource#date': 'pubblicato il',
  'resource#time_to_read': 'lettura di',
  'resource#metadata': 'Metadati',
  'resource#metadata_size': 'Dimensioni',
  'resource#collection-rewritings': 'Riscritture',
  'resource#collection-translations': 'Traduzioni correlate',
  'resource#related_records': 'Fonti correlate',
  'resource#page_bibliographic_data': 'Opera > Dati bibliografici',
  'resource#page_codicological_data': 'Opera > Dati codicologici',
  'resource#page_composition_process': 'Opera > Processo di composizione',
  'resource#page_cited_bibliography': 'Opera > Bibliografia citata',

  // Tabs
  'tabs#work_bibliographic_data': 'Dati bibliografici',
  'tabs#work_codicological_data': 'Dati codicologici',
  'tabs#work_composition_process': 'Processo di composizione',
  'tabs#work_cited_bibliography': 'Bibliografia citata',

  // Read More
  'readmore#label': 'Mostra tutto',
  'readmore#more': 'Leggi di più',
  'readmore#less': 'Leggi meno',

  // Author
  'author.name': 'Autore',

  // Editions
  'old_editions.edition': 'Edizioni antiche',
  'modern_editions.edition': 'Edizioni moderne'
};
